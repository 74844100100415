@use "../../public/styles/variables.scss";

.input-wrapper {
  .input-field {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 2px;
    padding: 7px 11px;
    margin-top: 16px;
    border-radius: 8px;
    background-color: variables.$neutral_03;
    height: 48px;
    
    .input {
      width: 100%;
      background: variables.$ui_01;
      border: none;
      outline: none;
      font-size: 20px;
      line-height: 32px;
      padding: 0;
      color: variables.$text_01;
      background-color: variables.$neutral_03;
      @include variables.P4;

      &::placeholder {
        color: variables.$text_03;
      }
    }

    .input-suffix {
      display: none;
    }

    &:focus-within {
      border: 1px solid variables.$link;
    }
  }

  .input-message {
    display: none;
    font-size: 14px;
    line-height: 24px;
    margin-top: 8px;
    color: variables.$error_01;
  }
  
  
  &.invalid {
    .input-field {
      background-color: variables.$error_03;

      input {
        background-color: variables.$error_03 !important;
      }

      .input-suffix {
        display: block;
      }
    }
  }

  &.filled.invalid {
    .input-message {
      display: block;
    }
  }

  &:first-child {
    margin-top: 0;
  }
}
