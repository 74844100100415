.input-wrapper .input-field {
  background-color: #eaf1ff;
  border: 1px solid #0000;
  border-radius: 8px;
  align-items: center;
  height: 48px;
  margin-top: 16px;
  padding: 7px 11px;
  display: flex;
  position: relative;
}

.input-wrapper .input-field .input {
  color: #232735;
  background: #eaf1ff;
  border: none;
  outline: none;
  width: 100%;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.input-wrapper .input-field .input::placeholder {
  color: #8b90a0;
}

.input-wrapper .input-field .input-suffix {
  display: none;
}

.input-wrapper .input-field:focus-within {
  border: 1px solid #536ced;
}

.input-wrapper .input-message {
  color: #e03f3f;
  margin-top: 8px;
  font-size: 14px;
  line-height: 24px;
  display: none;
}

.input-wrapper.invalid .input-field {
  background-color: #fee;
}

.input-wrapper.invalid .input-field input {
  background-color: #fee !important;
}

.input-wrapper.invalid .input-field .input-suffix, .input-wrapper.filled.invalid .input-message {
  display: block;
}

.input-wrapper:first-child {
  margin-top: 0;
}
/*# sourceMappingURL=input.44b895c9.css.map */
